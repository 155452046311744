<template>
  <div style="width: 100%; height: 100%;" :style="'text-align: ' + (alignLeft ? 'left' : 'center') + ';'">
    <div v-if="!isEdit" @click="editOn" class="activator" style="min-height: 1rem;">
      {{ record && record[keyValue].value ? (formatted(record[keyValue].value) || '\u00a0') : '\u00a0' }}
    </div>
    <div v-show="isEdit" :id="'edit_' + uuid" contenteditable="true" @blur="onBlur"
         @keydown="onKeyPress"
    />
    <cbs-debug v-if="false && isRoot" :context="this" />
  </div>
</template>

<script>
import CbsDebug from '@/cubus/components/debug/CbsDebug.vue'
import useCubus from '@/cubus/services/useCubus'
import nextCell, { selectAllText } from '@/@core/utils/next-cell'

export default {
  name: 'CbsBudreqCell',
  components: {
    CbsDebug,
  },
  props: {
    record: {
      type: Object,
      default: null,
    },
    keyValue: {
      type: String,
      default: null,
    },
    valueType: {
      type: String,
      default: 'string',
    },
    alignLeft: {
      type: Boolean,
      default: false,
    },
    access: {
      type: String,
      default: 'ro',
    },
    noNextCell: {
      type: Boolean,
      default: false,
    },
  },
  created() {
  },
  data() {
    return {
      uuid: useCubus.guid(),
      isEdit: false,
    }
  },
  setup() {
    return {
      isRoot: useCubus.isRoot(),
    }
  },
  methods: {
    onKeyPress(event) {
      if (event.key === 'Escape') {
        event.preventDefault()
        this.isEdit = false
      }
      nextCell(this.noNextCell, event)
    },
    formatNumber(value) {
      if (!value) return NaN
      if (typeof value !== 'string') return value
      let newValue = value
      newValue = newValue.replace(/\s/g, '')
      newValue = newValue.replace(',', '.')
      // console.log('newValue', newValue)
      return Number(newValue)
    },
    onBlur(event) {
      if (this.isEdit) {
        this.isEdit = false
        const srcValue = JSON.parse(JSON.stringify(event.target.innerText))
        let newValue = srcValue
        if (this.valueType === 'number') {
          newValue = this.formatNumber(srcValue)
        } else if (this.valueType === 'date') {
          // newValue = this.toDate(srcValue)
          newValue = this.parseDate(srcValue)
        }
        if ((this.record && this.record[this.keyValue].value !== newValue) || (!this.record && newValue)) {
          this.$emit('edit', newValue)
        }
      }
    },
    editOn() {
      // console.log('editOn', this.record, this.keyValue)
      if (this.record && 'cbs_access' in this.record) {
        if (this.record.cbs_access === 'ro') return
      } else if (this.record && 'cbs_sys_access' in this.record) {
        if (this.record.cbs_sys_access === 'ro') return
      } else if (this.access === 'ro') return
      this.isEdit = true
      this.$nextTick(() => {
        const el = document.getElementById(`edit_${this.uuid}`)
        if (this.valueType === 'date') {
          el.innerText = this.record ? this.formatDate(this.record[this.keyValue].value) : '\u00a0'
        } else {
          el.innerText = this.record ? this.record[this.keyValue].value : '\u00a0'
        }
        el.focus()
        selectAllText()
      })
    },
    isDate(dateString) {
      const d = new Date(dateString)
      if (!Number.isNaN(d.getTime())) return true

      const regex = /^(\d{2}).(\d{2}).(\d{4}).*$/
      const regex2 = /^\d{4}-\d{2}-\d{4}.*$/
      if (!dateString.match(regex) && !dateString.match(regex2)) return false
      let dt = dateString
      if (dateString.match(regex)) {
        dt = dateString.replace(regex, '$3-$2-$1')
      }
      const date = new Date(dt)
      const timestamp = date.getTime()
      return !Number.isNaN(timestamp) && dt === date.toISOString().split('T')[0]
    },
    toDate(dateString) {
      if (!this.isDate(dateString)) return null
      // const regex = /^(\d{2}).(\d{2}).(\d{4})$/
      // if (dateString.match(regex)) return dateString.replace(regex, '$3-$2-$1')
      return dateString
    },
    formatted(value) {
      // if (this.valueType === 'date') return this.toDate(value)
      if (this.valueType === 'date') return this.formatDate(value)
      if (this.valueType === 'number') {
        // return Number(value).toLocaleString()
        return new Intl.NumberFormat('ru-RU', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(Number(value))
      }
      if (this.valueType === 'percent') return `${(Number(value) * 100).toFixed(2)}%`
      if (this.valueType === 'percent4') return `${(Number(value) * 100).toFixed(4)}%`
      return value
    },
    formatDate(dateStr) {
      const dt = new Date(dateStr)
      const yyyy = dt.getFullYear()
      const mm = String(dt.getMonth() + 1).padStart(2, '0')
      const dd = String(dt.getDate()).padStart(2, '0')
      if (!dateStr || dateStr === '' || dateStr === '0') return ''
      const l = dateStr.split('-')
      // return `${l[2]}.${l[1]}.${l[0]}` // dd.mm.yyyy
      return `${dd}.${mm}.${yyyy}` // dd.mm.yyyy
    },
    parseDate(dateStr) {
      if (!dateStr) return ''
      const l = dateStr.split('.')
      return `${l[2]}-${l[1]}-${l[0]}` // yyyy-mm-dd
    },
  },
}
</script>

<style scoped>
.stick-th1 {
  position: sticky;
  left: 0;
  top: 0;
  z-index: 3;
}
.stick-th {
  position: sticky;
  top: 0;
  z-index: 2;
}
</style>
