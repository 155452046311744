<template>
  <div :class="root ? 'mt-1' : 'border-top-secondary mt-1 pt-1'">
    <b-row>
      <b-col>
        <b-button-toolbar key-nav aria-label="Toolbar with button groups" class="cbs-inline-spacing">
          <b-button-group size="sm" class="cbs-inline-spacing">
            <feather-icon :icon="node.isexpanded ? 'ChevronDownIcon' : 'ChevronRightIcon'"
                          class="cursor-pointer"
                          @click="node.isexpanded = !node.isexpanded" />&nbsp;&nbsp;
            <b-form-checkbox switch class="custom-control-success" v-model="node.isactive">
              <span class="switch-icon-left">
                <feather-icon icon="CheckIcon" />
              </span>
              <span class="switch-icon-right">
                <feather-icon icon="XIcon" />
              </span>
            </b-form-checkbox>&nbsp;&nbsp;
          </b-button-group>
          <b-button-group size="sm" class="cbs-inline-spacing">
            <b-button size="sm" variant="outline-primary" @click="switchOper()">
              <feather-icon icon="ZapIcon" />
              {{ node.oper.name }}
            </b-button>
            <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-primary" @click="addNode()">
              <feather-icon icon="PlusIcon" />
              Node
            </b-button>
            <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-primary" @click="addCond()">
              <feather-icon icon="PlusIcon" />
              Condition
            </b-button>
            <b-button v-if="!root" v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-danger" @click="onDelete">
              <feather-icon icon="TrashIcon" />
              Delete
            </b-button>
          </b-button-group>
          <b-button-group size="sm" class="cbs-inline-spacing">
            <span v-if="!isEdit" @dblclick="isEdit = !isEdit" class="font-weight-bold" style="font-size: 1.0rem;">
              &nbsp;&nbsp;&nbsp; {{ node.name }} &nbsp;&nbsp;&nbsp;
            </span>
            <b-form-input v-if="isEdit" v-model="node.name" placeholder="Enter Name" size="sm" @dblclick="isEdit = !isEdit"/>
          </b-button-group>
        </b-button-toolbar>
      </b-col>
    </b-row>
    <cbs-collapse :trigger="node.isexpanded">
      <b-row v-if="node.isexpanded && (node.nodes.length || node.conds.length)">
        <b-col>
          <div class="ml-1">
            <cbs-filter-node v-for="(subnode, nodeidx) in node.nodes"
                             :key="'node' + nodeidx"
                             :node="subnode"
                             :index="nodeidx"
                             :fields="fields"
                             :param-fields="paramFields"
                             @delete="onSubnodeDelete(nodeidx)"
            />
            <div>
              <cbs-filter-condition v-for="(cond, condidx) in node.conds"
                                    :key="'cond' + condidx"
                                    :condition="cond"
                                    :index="condidx"
                                    :fields="fields"
                                    :param-fields="paramFields"
                                    @delete="onCondDelete(condidx)"
              />
            </div>
          </div>
        </b-col>
      </b-row>
    </cbs-collapse>
  </div>
</template>

<script>
import {
  BButton, BButtonGroup, BButtonToolbar, BCol, BFormCheckbox, BFormInput, BRow, VBToggle,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import CbsFilterCondition from '@/cubus/components/filter/CbsFilterCondition.vue'
import useCubus from '@/cubus/services/useCubus'
import CbsCollapse from '@/cubus/components/collapsible/CbsCollapse.vue'

export default {
  name: 'CbsFilterNode',
  components: {
    CbsFilterCondition,
    BFormCheckbox,
    BRow,
    BCol,
    BButton,
    BButtonToolbar,
    BButtonGroup,
    BFormInput,
    CbsCollapse,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  props: {
    node: {
      type: Object,
      default: () => ({
        isactive: false,
        isexpanded: false,
        oper: {
          value: 'and',
          title: 'AND',
        },
      }),
    },
    root: {
      type: Boolean,
      default: false,
    },
    index: {
      type: Number,
      default: null,
    },
    fields: {
      type: Array,
      default: () => [],
    },
    paramFields: {
      type: Array,
      default: () => [],
    },
  },
  emits: [
    'delete',
  ],
  data() {
    return {
      opers: [
        { value: 'and', title: 'AND' },
        { value: 'or', title: 'OR' },
      ],
      isEdit: false,
      uuid: null,
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      this.uuid = useCubus.guid()
      // this.node.isexpanded = false
    },
    selectAnd() {
      // this.node.oper = { id: 1, sid: 'and', name: 'AND' }
      this.node.oper.id = 1
      this.node.oper.sid = 'and'
      this.node.oper.name = 'AND'
    },
    selectOr() {
      // this.node.oper = { id: 2, sid: 'or', name: 'OR' }
      this.node.oper.id = 2
      this.node.oper.sid = 'or'
      this.node.oper.name = 'OR'
    },
    switchOper() {
      if (this.node.oper.sid === 'and') {
        this.selectOr()
      } else {
        this.selectAnd()
      }
    },
    toggleExpanded() {
      this.node.isexpanded = !this.node.isexpanded
    },
    addNode() {
      this.node.nodes.push(this.blankNode())
      this.node.isexpanded = true
    },
    addCond() {
      this.node.conds.push(this.blankCond())
      this.node.isexpanded = true
    },
    onDelete() {
      console.log('delete node', this.index)
      this.$emit('delete', this.index)
    },
    onSubnodeDelete(index) {
      console.log('delete subnode', index)
      this.node.nodes.splice(index, 1)
    },
    onCondDelete(index) {
      console.log('delete cond', index)
      this.node.conds.splice(index, 1)
    },
    expandIcon() {
      console.log('expandIcon', this.node)
      if (this.node.isexpanded) {
        return 'ChevronDownIcon'
      }
      return 'ChevronRightIcon'
      /*
      if (!this.node.nodes.length && !this.node.conds.length) {
        return 'XIcon'
      }
      if (this.node.isexpanded) {
        return 'MinusIcon'
      }
      return 'PlusIcon'
      */
    },
    blankNode() {
      return {
        isactive: false,
        isexpanded: true,
        name: `new Node ${this.node.nodes.length + 1}`,
        oper: { id: 1, sid: 'and', name: 'AND' },
        nodes: [],
        conds: [],
      }
    },
    blankCond() {
      return {
        isactive: false,
        name: `new Condition ${this.node.conds.length + 1}`,
        oper: {
          id: 1, sid: 'equal', name: '=', args: 2,
        },
        args: [
          {
            type: { id: 1, sid: 'entity', name: 'Entity' }, datatype: null, entityid: null, value: null,
          },
          {
            type: { id: 2, sid: 'value', name: 'Value' }, datatype: null, entityid: null, value: null,
          },
        ],
      }
    },
  },
}
</script>

<style scoped>
.cbs-inline-spacing {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}
</style>
