<template>
  <div>
    <cbs-card-actions action-close :title="title" @close="onCancel">
      <b-card-body>
        <div v-if="isSubs()" class="mb-2">
          <b-button-toolbar size="sm">
            <b-button-group size="sm">
              <b-button size="sm" :variant="tabVariant(0)"
                        @click="onSelectTab(0)"
              >
                <feather-icon icon="HomeIcon" />
                Main
              </b-button>
              <b-button v-for="subobj in objectFull.children" :key="subobj.object.id"
                        size="sm" :variant="tabVariant(subobj.object.id)" :disabled="!record.id || !record.id.value || record.id.value === 0"
                        @click="onSelectTab(subobj.object.id)"
              >
                {{subobj.object.name}} ({{subobj.object.id}})
              </b-button>
            </b-button-group>
            <b-button-group size="sm">
              <b-button v-for="relobj in relatedRecordPage()" :key="relobj.id"
                        size="sm" :variant="tabVariant(relobj.id)" :disabled="!record.id || !record.id.value || record.id.value === 0"
                        @click="onClickRelObj(relobj.id)"
                        target="_blank"
                        :href="'/cubus/recordpage/' + relobj.sid + '/' + record.sid.value"
                        title="Open in new tab"
              >
                {{relobj.name}} ({{relobj.id}})
              </b-button>
            </b-button-group>
          </b-button-toolbar>
        </div>
        <cbs-collapse :trigger="activeTab() === 0">
          <div>
            <cbs-record-form :hide="!isDebug" :object="objectFull" :fields="fields" :record="record" :compare="initRecord" />
            <!-- buttons -->
            <b-button-toolbar
                key-nav
                aria-label="Toolbar with button groups"
                class="demo-inline-spacing"
            >
              <b-button-group size="sm" class="cbs-inline-spacing">
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="danger"
                    @click="onCancel"
                >
                  <feather-icon icon="XIcon" />
                  {{t('Cancel')}}
                </b-button>
              </b-button-group>
              <b-button-group size="sm" class="cbs-inline-spacing">
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="success"
                    :disabled="!isSaveable() || isSaving"
                    @click="onSaveAndClose"
                >
                  <feather-icon icon="CheckIcon" />
                  {{t( isNew() ? 'Create and Close' : 'Save')}}
                </b-button>
              </b-button-group>
              <b-button-group v-if="isNew()" size="sm" class="cbs-inline-spacing">
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="success"
                    :disabled="!isSaveable() || isSaving"
                    @click="onCreateAndClear"
                >
                  <feather-icon icon="CheckIcon" />
                  {{t('Create and Clear')}}
                </b-button>
              </b-button-group>
              <b-button-group v-if="isNew()" size="sm" class="cbs-inline-spacing">
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="success"
                    :disabled="!isSaveable() || isSaving"
                    @click="onSave"
                >
                  <feather-icon icon="CheckIcon" />
                  {{t('Create')}}
                </b-button>
              </b-button-group>
              <b-button-group v-if="isNew()" size="sm" class="cbs-inline-spacing">
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="success"
                    :disabled="!isSaveable() || isSaving"
                    @click="onCreateAndUpdate"
                >
                  <feather-icon icon="CheckIcon" />
                  {{t('Create & Update')}}
                </b-button>
              </b-button-group>
              <b-button-group v-if="isAdmin()" size="sm" class="cbs-inline-spacing">
                <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" :variant="isDebug ? 'primary' : 'outline-primary'"
                          @click="isDebug = !isDebug"
                >
                  <feather-icon icon="CodeIcon" /> {{ $options.name }}
                </b-button>
              </b-button-group>
            </b-button-toolbar>
            <!-- /buttons -->
            <div v-if="objectFull && objectFull.object.sid === 'mountstep'">
              <cbs-data-object v-if="record && record.sourceobjectid && record.sourceobjectid.value"
                               :ref="`cbsRecordCard-mountStep-srcObject_${uuid}`"
                               :hide="true"
                               :objectid="record.sourceobjectid.value"
                               @load="filterSrcObjReady"
              />
              <cbs-data-object v-if="record && record.destobjectid && record.destobjectid.value"
                               :ref="`cbsRecordCard-mountStep-destObject_${uuid}`"
                               :hide="true"
                               :objectid="record.destobjectid.value"
                               @load="filterDestObjReady"
              />
              <cbs-data-object v-if="record && record.objectid && record.objectid.value"
                               :ref="`cbsRecordCard-mountStep-object_${uuid}`"
                               :hide="true"
                               :objectid="record.objectid.value"
                               @load="filterParamObjReady"
              />
              <cbs-data-object hide objectsid="mountoperand" @load="loadMountOperObj" />
              <cbs-data-record v-if="mountOperObj && record.mountoperid && record.mountoperid.value"
                               :object-full="mountOperObj" hide :rowid="record.mountoperid.value" @load="loadMountOperRec"
              />
            </div>
            <div v-if="objectFull && objectFull.object.sid === 'scalar'">
              <cbs-data-object v-if="record && record.sourceobjectid && record.sourceobjectid.value"
                               :hide="true"
                               :objectid="record.sourceobjectid.value"
                               @load="filterSrcObjReady"
              />
            </div>
          </div>
        </cbs-collapse>
        <cbs-collapse :trigger="activeTab() !== 0">
          <div v-if="activeTab() !== 0" class="border-secondary" style="border-radius: 5px;">
            <!--<cbs-ctrl-relation :objectid="activeTab()" :filter="subObjectFilter()" :hidden-fields="hiddenFields()" />-->
            <cbs-subobject :subobject="currentSubobject"
                           :record="record"
                           :objectid="activeTab()"
                           :filter="subObjectFilter()"
                           :hidden-fields="hiddenFields()"
            />
          </div>
        </cbs-collapse>
      </b-card-body>
    </cbs-card-actions>
    <div v-if="false">
      <p>isSaveable: {{isSaveable()}}</p>
      <p>recordStatus: {{recordStatus()}}</p>
      <p>recordState: {{printRecordState()}}</p>
    </div>
    <cbs-debug v-if="session.user.sid === 'root' && isDebug" :context="this" />
  </div>
</template>

<script>
import CbsCardActions from '@/cubus/components/cbs-card-actions/CbsCardActions.vue'
import {
  BButton, BButtonGroup, BButtonToolbar, BCardBody, VBToggle,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import useJwt from '@/cubus/jwt/useJwt'
import useCubus from '@/cubus/services/useCubus'
import CbsDataObject from '@/cubus/components/query/CbsDataObject.vue'
import CbsDataRecord from '@/cubus/components/query/CbsDataRecord.vue'
import CbsRecordForm from '@/cubus/components/record/CbsRecordForm.vue'
import CbsDebug from '@/cubus/components/debug/CbsDebug.vue'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import CbsCollapse from '@/cubus/components/collapsible/CbsCollapse.vue'
import CbsSubobject from '@/cubus/components/subobject/CbsSubobject.vue'

export default {
  name: 'CbsRecordCard',
  components: {
    CbsSubobject,
    CbsDebug,
    CbsRecordForm,
    CbsDataRecord,
    CbsCardActions,
    BCardBody,
    BButton,
    BButtonGroup,
    BButtonToolbar,
    CbsDataObject,
    CbsCollapse,
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple,
  },
  props: {
    fields: {
      type: Array,
      default: () => ([]),
    },
    propRecord: {
      type: Object,
      default: () => ({}),
    },
    title: {
      type: String,
      default: null,
    },
    rowIndex: {
      type: Number,
      default: -1,
    },
    objectFull: {
      type: Object,
      default: null,
    },
    mode: {
      type: String,
      default: 'edit',
    },
  },
  setup() {
    const { t } = useI18nUtils()
    const session = useCubus.getSession()
    return {
      t, // i18n
      session,
    }
  },
  emits: [
    'save',
    'cancel',
    'saveandclose',
    'saveandclear',
    'saveandupdate',
  ],
  data() {
    return {
      refs: {},
      testprop: 0,
      record: JSON.parse(JSON.stringify(this.propRecord)),
      initRecord: JSON.parse(JSON.stringify(this.propRecord)),
      isDebug: false,
      uuid: useCubus.guid(),
      isSaving: false,
      filterSrcObj: null,
      filterDestObj: null,
      filterParamObj: null,
      mountOperObj: null,
      mountOperRec: null,
      currentSubobject: null,
    }
  },
  computed: {
    locale() {
      return useCubus.locale(this.$i18n.locale)
    },
  },
  watch: {
    propRecord(newValue, oldValue) {
      console.log('watch prop-record', oldValue, newValue)
      this.record = this.propRecord
      this.initRecord = JSON.parse(JSON.stringify(this.propRecord))
    },
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      console.log('CbsRecordCard : propRecord', this.propRecord)
      // console.log('objectFull', this.objectFull)
      this.initRefs()
      console.log('initRefs done')
      // this.locale = useCubus.locale(this.$i18n.locale)
    },
    initRefs() {
      this.fields.filter(f => f.datatype === 'ref' || f.entitysid === 'parent').forEach(fld => {
        const entid = fld.datatype === 'ref' ? fld.entityid : fld.parententityid
        this.$set(this.refs, entid, [])
        this.loadEntRef(entid)
      })
    },
    onCancel() {
      this.$emit('close')
    },
    onSave() {
      this.isSaving = true
      this.fields.forEach(fld => {
        this.propRecord[fld.key] = this.record[fld.key]
        this.propRecord[fld.key].status = this.cellStatus(fld)
      })
      this.propRecord.status = this.recordStatus()
      // eslint-disable-next-line no-underscore-dangle
      this.propRecord._showDetails = false
      this.$emit('save')
    },
    onCreateAndClear() {
      this.isSaving = true
      this.fields.forEach(fld => {
        this.propRecord[fld.key] = this.record[fld.key]
        this.propRecord[fld.key].status = this.cellStatus(fld)
      })
      this.propRecord.status = this.recordStatus()
      // eslint-disable-next-line no-underscore-dangle
      this.propRecord._showDetails = false
      this.$emit('saveandclear')
    },
    onCreateAndUpdate() {
      this.isSaving = true
      this.fields.forEach(fld => {
        this.propRecord[fld.key] = this.record[fld.key]
        this.propRecord[fld.key].status = this.cellStatus(fld)
      })
      this.propRecord.status = this.recordStatus()
      // eslint-disable-next-line no-underscore-dangle
      this.propRecord._showDetails = false
      this.$emit('saveandupdate')
    },
    onSaveAndClose() {
      this.isSaving = true
      this.fields.forEach(fld => {
        this.propRecord[fld.key] = this.record[fld.key]
        this.propRecord[fld.key].status = this.cellStatus(fld)
      })
      this.propRecord.status = this.recordStatus()
      // eslint-disable-next-line no-underscore-dangle
      this.propRecord._showDetails = false
      this.$emit('saveandclose')
    },
    getRef(objectid) {
      if (!this.refs[objectid]) {
        this.loadRef(objectid)
      }
      return this.refs[objectid]
    },
    getEntRef(fld) {
      if (!this.refs[fld.entityid]) {
        this.loadEntRef(fld.entityid)
      }
      return this.refs[fld.entityid]
    },
    getParentRef(fld) {
      if (!this.refs[fld.parententityid]) {
        this.loadEntRef(fld.parententityid)
      }
      return this.refs[fld.parententityid]
    },
    loadRef(objectid) {
      useJwt.query({
        token: localStorage.getItem(useJwt.jwtConfig.storageCubusTokenKeyName),
        query: {
          method: 'ref',
          param: { objectid },
        },
      })
        .then(response => {
          // console.log('ref response', response)
          // const key = `obj${objectid}`
          // const key = objectid
          // console.log('key', key)
          if (response.data.ref) {
            // this.refs[key] = response.data.ref.ref
            this.$set(this.refs, objectid, response.data.ref.ref)
            // console.log('refs', this.refs)
          } else if (response.data.error) {
            useCubus.toastError(this, response.data.error)
          }
        })
        .catch(error => {
          console.log('ref error', error)
        })
    },
    loadEntRef(entityid) {
      useJwt.query({
        token: localStorage.getItem(useJwt.jwtConfig.storageCubusTokenKeyName),
        query: {
          method: 'entref',
          param: { entityid },
        },
      })
        .then(response => {
          // console.log('entref response', response)
          if (response.data.thread) this.loadThreadEntref(entityid, response.data.thread)
          if (response.data.error) useCubus.toastError(this, response.data.error)
        })
        .catch(error => {
          console.log('entref error', error)
          useCubus.toastError(this, error)
        })
    },
    delayEntref(entityid, threadname) {
      setTimeout(() => this.loadThreadEntref(entityid, threadname), 500)
    },
    loadThreadEntref(entityid, threadname) {
      useJwt.query({
        token: localStorage.getItem(useJwt.jwtConfig.storageCubusTokenKeyName),
        query: {
          method: 'thread',
          param: { threadname },
        },
      })
        .then(response => {
          if (response.data.thread) {
            if (response.data.thread.status === 'done') {
              // console.log('loadThreadEntref', response)
              // this.$set(this.refs, entityid, response.data.thread.result.ref)
              this.refs[entityid] = response.data.thread.result.ref
            } else if (response.data.thread.status === 'error') {
              useCubus.toastError(this, response.data.thread.error)
            } else {
              this.delayEntref(entityid, threadname)
            }
          }
        })
        .catch(error => {
          console.log('loadThreadEntref error', error)
          useCubus.toastError(this, error)
        })
    },
    cellLabel(field) {
      const cell = this.record[field.key]
      const initCell = this.initRecord[field.key]
      if (cell) {
        const stat = (cell.value === initCell.value || (!cell.value && !initCell.value)) ? '' : ' (changed)'
        return field.label + stat
      }
      return ''
    },
    cellStatus(field) {
      // console.log('cellStatus', field)
      // console.log('record', this.record)
      // console.log('initRecord', this.initRecord)
      const cell = this.record[field.key]
      // console.log('cell', cell)
      const initCell = this.initRecord[field.key]
      // console.log('initCell', initCell)
      if (cell) {
        if (field.rendertype === 'jsonfilter') {
          return (`${JSON.stringify(cell.value)}` === `${JSON.stringify(initCell.value)}` || (!cell.value && !initCell.value)) ? 'init' : 'changed'
        }
        if (field.datatype === 'json') {
          return (`${JSON.stringify(cell.value)}` === `${JSON.stringify(initCell.value)}`) ? 'init' : 'changed'
        }
        return (`${cell.value}` === `${initCell.value}` || (!cell.value && !initCell.value)) ? 'init' : 'changed'
      }
      return 'init'
    },
    isCellChanged(fld) {
      return this.cellStatus(fld) === 'changed'
    },
    onInputVSelect(val, index) {
      if (!val) this.record[this.fields[index].key] = { value: 0, title: '' }
    },
    recordStatus() {
      if (this.fields.find(fld => this.cellStatus(fld) === 'changed')) return 'changed'
      /*
      for (const fld of this.fields) {
        if (this.cellStatus(fld) === 'changed') return 'changed'
      }
      */
      return 'init'
    },
    formatter(value) {
      return Number(value)
    },
    onContext(ctx) {
      // The date formatted in the locale, or the `label-no-date-selected` string
      this.formatted = ctx.selectedFormatted
      // The following will be an empty string until a valid date is entered
      this.selected = ctx.selectedYMD
    },
    isAdmin() {
      const sess = this.$store.getters['cubus-store/SESSION']
      return sess && sess.user && sess.user.sid && (sess.user.sid === 'root' || sess.user.sid === 'batman')
    },
    field(key) {
      return this.fields.find(f => f.key === key)
    },
    fldState(fld) {
      // console.log('fld', fld)
      let stat = { state: null, status: '' }
      const isEmpty = this.isCellEmpty(fld)
      if (fld.isrequired) {
        if (isEmpty) {
          stat = { state: false, status: 'Attribute is required.' }
        } else if (this.isCellChanged(fld)) {
          stat = { state: true, status: '' }
        }
      }
      if (fld.isunique && !isEmpty) {
        if (!this.isCellChanged(fld)) {
          stat = { state: null, status: '' }
        } else if (this.record[fld.key].isUnique === true) {
          stat = { state: true, status: '' }
        } else if (this.record[fld.key].isUnique === false) {
          stat = { state: false, status: 'Value is not unique.' }
        } else {
          stat = { state: false, status: 'Unique check is required.' }
        }
      }
      // if (!stat.state) { console.log('fldState', stat, fld) }
      return stat
    },
    recordState() {
      if (this.fields.find(fld => this.fldState(fld).state === false)) {
        return false
      }
      return true
    },
    printRecordState() {
      return this.fields.map(fld => `${fld.key} : ${JSON.stringify(this.fldState(fld))}`).join('\n')
    },
    isSaveable() {
      if (this.objectFull) {
        return this.objectFull
            && useCubus.isCredentialAvailable(this.objectFull.access, 'update')
            && this.recordStatus() !== 'init'
            && this.recordState()
      }
      return this.recordStatus() !== 'init' && this.recordState()
    },
    selectClass(fld) {
      if (this.fldState(fld).state === null) return null
      if (this.fldState(fld).state === true) return 'border rounded border-success'
      return 'border rounded border-danger'
    },
    checkUnique(fld, cell) {
      console.log('checkUnique', fld, cell)
      useJwt.query({
        token: localStorage.getItem(useJwt.jwtConfig.storageCubusTokenKeyName),
        query: {
          method: 'checkunique',
          param: {
            objectid: this.objectFull.object.id,
            entityid: fld.entityid,
            value: cell.value,
          },
        },
      })
        .then(response => {
          console.log('check unique response', response)
          if (response.data) {
            if (response.data.thread) this.loadThreadCheckUnique(response.data.thread, cell)
            if (response.data.error) {
              useCubus.toastError(this, response.data.error)
            }
          }
        })
        .catch(error => {
          console.log('check unique error', error)
        })
    },
    delayThreadCheckUnique(threadName, cell) {
      setTimeout(() => this.loadThreadCheckUnique(threadName, cell), 500)
    },
    loadThreadCheckUnique(threadName, cell) {
      useJwt.query({
        token: localStorage.getItem(useJwt.jwtConfig.storageCubusTokenKeyName),
        query: {
          method: 'thread',
          param: { threadname: threadName },
        },
      })
        .then(response => {
          console.log('loadThreadCheckUnique', response)
          if (response.data) {
            if (response.data.error) {
              this.toast(response.data.error)
            } else if (response.data.thread) {
              if (response.data.thread.status === 'done' && response.data.thread.result) {
                // cell.isUnique = response.data.thread.result.isUnique
                this.$set(cell, 'isUnique', response.data.thread.result.record.isUnique)
              } else {
                this.delayThreadCheckUnique(threadName, cell)
              }
            }
          }
        })
        .catch(error => {
          console.log('thread error', error)
        })
    },
    isEmpty(fld, cell) {
      return cell.value === null || cell.value === '' || (fld.datatype === 'ref' && cell.value === 0)
    },
    isCellEmpty(fld) {
      const cell = this.record[fld.key]
      return cell.value === null || cell.value === '' || (fld.datatype === 'ref' && cell.value === 0)
    },
    onChange(fld) {
      console.log('onChange', fld)
      this.checkUnique(fld, this.record[fld.key])
    },
    clearCell(fld) {
      this.record[fld.key] = { value: null, title: null }
    },
    filterBy(option, label, search) {
      const pattern = `.*${search
        .replace(new RegExp(' ', 'gi'), '.*')
        .replace(new RegExp('\\.\\*\\.\\*', 'gi'), ' ')}.*`
      const re = new RegExp(pattern, 'gi')
      return re.test(label || '')
    },
    canDownload() {
      return this.objectFull.object && this.objectFull.object.access
          && (this.objectFull.object.access.download === 'grant' || this.objectFull.object.access.download === 'withgrant')
    },
    filterFields() {
      if (this.objectFull.object.sid === 'mountstep') {
        if (this.mountOperRec) {
          if (this.mountOperRec.record.sid.value === 'insert') {
            if (this.filterSrcObj) {
              return this.filterSrcObj.attributes.map(a => useCubus.attributeToField(a))
            }
            return []
          }
          if (this.filterDestObj) {
            return this.filterDestObj.attributes.map(a => useCubus.attributeToField(a))
          }
          return []
        }
        return []
      }
      if (this.objectFull.object.sid === 'scalar') {
        if (this.filterSrcObj) {
          return this.filterSrcObj.attributes.map(a => useCubus.attributeToField(a))
        }
        return []
      }
      return this.fields.map(f => useCubus.attributeToField(f))
    },
    destinationFields() {
      if (this.filterDestObj) {
        return this.filterDestObj.attributes.map(a => useCubus.attributeToField(a))
      }
      return []
    },
    filterParamFields() {
      if (this.objectFull.object.sid === 'mountstep') {
        if (this.filterParamObj) {
          return this.filterParamObj.attributes.map(a => useCubus.attributeToField(a))
        }
        return []
      }
      return this.fields.map(f => useCubus.attributeToField(f))
    },
    filterSrcObjReady(obj) {
      this.filterSrcObj = obj
    },
    filterDestObjReady(obj) {
      this.filterDestObj = obj
    },
    filterParamObjReady(obj) {
      this.filterParamObj = obj
    },
    loadMountOperObj(obj) {
      console.log('loadMountOperObj', obj)
      this.mountOperObj = obj
    },
    loadMountOperRec(rec) {
      this.mountOperRec = rec
    },
    fieldWidth(fld) {
      if (fld.rendertype === 'jsonfilter') { return 12 }
      if (fld.rendertype === 'jsonmodification') { return 12 }
      if (fld.datatype === 'json') { return 12 }
      // if (this.isPeriod() && fld.entitysid === 'finish') return 12
      return 6
    },
    isPeriod() {
      return this.record.start && this.record.finish
    },
    cardFields() {
      /*
      const flds = []
      this.fields.forEach(fld => {
        if (!this.isPeriod() || (this.isPeriod() && fld.entitysid !== 'start')) flds.push(fld)
      })
      return flds
      */
      return this.fields
    },
    cellState(fld) {
      return {
        check: this.fldState(fld).state,
        status: this.fldState(fld).status,
        change: this.cellStatus(fld),
      }
    },
    isNew() {
      // return this.mode === 'create'
      return this.record && this.record.id && (!this.record.id.value || this.record.id.value === 0)
    },
    savingDone() {
      console.log('savingDone')
      this.isSaving = false
    },
    isSubs() {
      return this.isSubObjects() || this.isRelatedObjects()
    },
    isSubObjects() {
      return this.objectFull && this.objectFull.subobjects && this.objectFull.subobjects.length > 0
    },
    isRelatedObjects() {
      return this.objectFull && this.objectFull.relatedobjects && this.objectFull.relatedobjects.length > 0
    },
    onSelectTab(subobjid) {
      this.currentSubobject = this.objectFull.subobjects.find(s => s.id === subobjid)
    },
    activeTab() {
      if (!this.currentSubobject) {
        return 0
      }
      return this.currentSubobject.id
    },
    tabVariant(subobjid) {
      if (this.activeTab() === subobjid) return 'primary'
      return 'light'
    },
    subObjectFilter() {
      // const subobj = this.currentSubobject
      return {
        node: {
          isactive: true,
          oper: { sid: 'and' },
          nodes: [],
          conds: [
            {
              isactive: true,
              oper: { sid: 'equal' },
              args: [
                { type: { sid: 'entitysid' }, value: 'ownersid' },
                { type: { sid: 'value' }, value: `${this.record.sid.value}` },
              ],
            },
          ],
        },
      }
    },
    hiddenFields() {
      return ['actions', 'id', 'del', this.currentSubobject.parentkey]
    },
    onClickRelObj(relobjid) {
      console.log('onClickRelObj', relobjid)
    },
    relatedRecordPage() {
      return this.objectFull.relatedobjects.filter(el => el.meta === 'recordpage')
    },
  },
}
</script>

<style scoped>

</style>
