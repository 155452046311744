<template>
  <div>
    <div>
      <b-button-toolbar key-nav aria-label="Toolbar with button groups" class="demo-inline-spacing">
        <b-button-group size="sm" class="cbs-inline-spacing mb-1">
          <b-button title="Создать новую заявку"
                    :variant="hasEmptyRecord() ? 'outline-primary' : 'primary'" :disabled="hasEmptyRecord()"
                    @click="createRecord()"
          >
            <feather-icon icon="PlusIcon" />&nbsp;Создать
          </b-button>
        </b-button-group>
        <b-button-group size="sm" class="cbs-inline-spacing mb-1">
          <b-button title="Пересчитать выбранные записи"
                    :disabled="selected().length === 0"
                    :variant="selected().length === 0 ? 'outline-primary' : 'primary'"
                    @click="recalcRecords()"
          >
            <feather-icon icon="RefreshCwIcon" />&nbsp;Пересчитать ({{ selected().length }})
          </b-button>
        </b-button-group>
        <b-button-group size="sm" class="cbs-inline-spacing mb-1">
          <b-button title="Пересчитать все записи"
                    variant="outline-danger"
                    @click="askRecalcAll()"
          >
            <feather-icon icon="RefreshCwIcon" />&nbsp;Пересчитать все
          </b-button>
        </b-button-group>
        <b-button-group size="sm" class="cbs-inline-spacing mb-1">
          <b-button title="Удалить выбранные записи"
                    :disabled="selected().length === 0"
                    :variant="selected().length === 0 ? 'outline-danger' : 'danger'"
                    @click="askDelete()"
          >
            <feather-icon icon="TrashIcon" />&nbsp;Удалить ({{ selected().length }})
          </b-button>
        </b-button-group>
        <div v-if="qryTotal" style="margin: 0.5rem 0 0 0;">
          <span>Всего: <b>{{ formatNumber(qryTotal.recordset.records[0].amount.value) }}</b></span>
          <span style="margin-left: 1rem;">Проживание: <b>{{ formatNumber(qryTotal.recordset.records[0].accom_amount.value) }}</b></span>
          <span style="margin-left: 1rem;">Проезд: <b>{{ formatNumber(qryTotal.recordset.records[0].travel_amount.value) }}</b></span>
          <span style="margin-left: 1rem;">Суточные: <b>{{ formatNumber(qryTotal.recordset.records[0].perdiem_amount.value) }}</b></span>
          <span style="margin-left: 1rem;">Визы: <b>{{ formatNumber(qryTotal.recordset.records[0].amount_visa.value) }}</b></span>
        </div>
      </b-button-toolbar>
      <div class="cbs-table" style="min-height: 12rem;">
        <table v-if="dataReady" class="table table-bordered">
          <thead>
            <tr class="cbs-row">
              <th :id="`corner_${uuid}`" class="cbs-th-corner">ID</th>
              <th class="cbs-th1 cbs-stick-left">Цель</th>
              <th class="cbs-th">Подразделение</th>
              <th class="cbs-th">Должность</th>
              <th colspan="2" class="cbs-th" >Место проведения</th>
              <th class="cbs-th">Месяц проведения</th>
              <th class="cbs-th">Кол-во чел</th>
              <th class="cbs-th">Визы</th>
              <th class="cbs-th" colspan="2">Длительность</th>
              <th class="cbs-th">Статья</th>
              <th class="cbs-th">Сумма на 1 чел</th>
              <th class="cbs-th">Сумма</th>
              <th class="cbs-th">Итого</th>
              <th class="cbs-th">Автор</th>
              <th class="cbs-th">Создано</th>
            </tr>
            <tr class="cbs-row">
              <th class="cbs-cell cbs-flt-corner cbs-stick-top" style="text-align: center;">
                <cbs-budreq-filter :column="getColumn('id')" :condition="getCondition('id')" @apply="applyFilter()" />
              </th>
              <th class="cbs-cell cbs-flt1 cbs-stick-top cbs-stick-left" style="text-align: center;">
                <cbs-budreq-filter :column="getColumn('purpose')" :condition="getCondition('purpose')" @apply="applyFilter()" />
              </th>
              <th class="cbs-cell cbs-flt cbs-stick-top flt0" style="text-align: center;">
                <cbs-budreq-filter :column="getColumn('business_unit')"
                                   :condition="getCondition('business_unit')"
                                   :reference="ref(qryUnit)"
                                   @apply="applyFilter()" />
              </th>
              <th class="cbs-cell cbs-flt cbs-stick-top flt0" style="text-align: center;">
                <span style="cursor: pointer;" :class="getCondition('position').isactive ? 'text-success' : ''" @click="toggleFilter('position')" title="Должность"><feather-icon icon="FilterIcon"/> Д</span>&nbsp;&nbsp;/&nbsp;&nbsp;
                <span style="cursor: pointer;" :class="getCondition('position_category').isactive ? 'text-success' : ''" @click="toggleFilter('category')" title="Категория"><feather-icon icon="FilterIcon"/> К</span>
                <cbs-budreq-filter :ref="`flt_position_${uuid}`"
                                   no-icon
                                   :column="getColumn('position')"
                                   :condition="getCondition('position')"
                                   :reference="ref(qryPosition)"
                                   @apply="applyFilter()" />
                <cbs-budreq-filter :ref="`flt_category_${uuid}`"
                                   no-icon
                                   :column="getColumn('position_category')"
                                   :condition="getCondition('position_category')"
                                   :reference="ref(qryCategory)"
                                   @apply="applyFilter()" />
              </th>
              <th colspan="2" class="cbs-cell cbs-flt cbs-stick-top flt0" style="text-align: center;">
                <span :class="getCondition('city').isactive ? 'text-success' : ''" style="cursor: pointer;" @click="toggleFilter('city')" title="Город"><feather-icon icon="FilterIcon"/> Г</span>&nbsp;&nbsp;/&nbsp;&nbsp;
                <span :class="getCondition('country').isactive ? 'text-success' : ''" style="cursor: pointer;" @click="toggleFilter('country')" title="Страна"><feather-icon icon="FilterIcon"/> С</span>&nbsp;&nbsp;/&nbsp;&nbsp;
                <span :class="getCondition('region').isactive ? 'text-success' : ''" style="cursor: pointer;" @click="toggleFilter('region')" title="Регион"><feather-icon icon="FilterIcon"/> Р</span>
                <cbs-budreq-filter :ref="`flt_city_${uuid}`"
                                   :column="getColumn('city')"
                                   :condition="getCondition('city')"
                                   :reference="ref(qryCity)"
                                   no-icon
                                   @apply="applyFilter()" />
                <cbs-budreq-filter :ref="`flt_country_${uuid}`"
                                   :column="getColumn('country')"
                                   :condition="getCondition('country')"
                                   :reference="ref(qryCountry)"
                                   no-icon
                                   @apply="applyFilter()" />
                <cbs-budreq-filter :ref="`flt_region_${uuid}`"
                                   :column="getColumn('region')"
                                   :condition="getCondition('region')"
                                   :reference="ref(qryRegion)"
                                   no-icon
                                   @apply="applyFilter()" />
              </th>
              <th class="cbs-cell cbs-flt cbs-stick-top" style="text-align: center;"></th>
              <th class="cbs-cell cbs-flt cbs-stick-top" style="text-align: center;"></th>
              <th class="cbs-cell cbs-flt cbs-stick-top" style="text-align: center;"></th>
              <th colspan="2" class="cbs-cell cbs-flt cbs-stick-top" style="text-align: center;"></th>
              <th class="cbs-cell cbs-flt cbs-stick-top" style="text-align: center;"></th>
              <th class="cbs-cell cbs-flt cbs-stick-top" style="text-align: center;"></th>
              <th class="cbs-cell cbs-flt cbs-stick-top" style="text-align: center;"></th>
              <th class="cbs-cell cbs-flt cbs-stick-top" style="text-align: center;"></th>
              <th class="cbs-cell cbs-flt cbs-stick-top" style="text-align: center;"></th>
              <th class="cbs-cell cbs-flt cbs-stick-top" style="text-align: center;"></th>
            </tr>
          </thead>
          <tbody v-for="(rec, idx) in qryTable.recordset.records" :key="idx">
            <tr class="cbs-row">
              <td rowspan="3" class="cbs-cell cbs-col0 table-light">
                <div style="text-align: center;"
                     :style="rec.cbs_sys_access === 'rw' ? 'cursor: pointer;' : ''"
                     @click="selectRec(rec)"
                >
                  <feather-icon v-if="rec.cbs_sys_access === 'rw'" :icon="rec.cbs_sys_selected ? 'CheckSquareIcon' : 'SquareIcon'" />
                  <feather-icon v-else icon="LockIcon" />
                  &nbsp;{{ rec.id.value }}
                </div>
              </td>
              <td rowspan="3" class="cbs-cell cbs-stick-left table-light" :style="styleStickLeft()">
                <cbs-budreq-cell align-left :record="rec" key-value="purpose" @edit="onEdit(rec, 'purpose', $event)" />
              </td>
              <td rowspan="3" class="cbs-cell" style="text-align: center;">
                <cbs-reference-picker3 :record="rec"
                                       :field="{ key: 'business_unit' }"
                                       :prop-ref="ref(qryUnit)"
                                       @updateCell="onUpdateCell(rec, 'business_unit', $event)"
                />
              </td>
              <td rowspan="2" class="cbs-cell">
                <cbs-reference-picker3 :record="rec"
                                       :field="{ key: 'position' }"
                                       :prop-ref="ref(qryPosition)"
                                       @updateCell="onUpdateCell(rec, 'position', $event)"
                />
              </td>
              <td class="cbs-cell text-secondary" style="text-align: right;"><small>Город:</small></td>
              <td class="cbs-cell">
                <!--<cbs-reference-picker3 :record="rec"
                                       :field="{ key: 'city' }"
                                       :prop-ref="ref(qryCity)"
                                       @updateCell="onUpdateCell(rec, 'city', $event)"
                />-->
                <cbs-budreq-cell align-left :record="rec" key-value="city_name" @edit="onEdit(rec, 'city_name', $event)" />
              </td>
              <td rowspan="3" class="cbs-cell">
                <cbs-budreq-cell :record="rec" key-value="date" value-type="date" @edit="onEdit(rec, 'date', $event)"
                                 access="rw" />
              </td>
              <td rowspan="3" class="cbs-cell">
                <cbs-budreq-cell :record="rec" key-value="person_qty" value-type="number" @edit="onEdit(rec, 'person_qty', $event)" />
              </td>
              <td class="cbs-cell">
                <cbs-budreq-cell :record="rec" key-value="amount_cur_visa" value-type="number" @edit="onEdit(rec, 'amount_cur_visa', $event)" />
              </td>
              <td class="cbs-cell text-secondary" style="text-align: right;"><small>Мероприятия:</small></td>
              <td class="cbs-cell">
                <cbs-budreq-cell :record="rec" key-value="trip_days" value-type="number" @edit="onEdit(rec, 'trip_days', $event)" />
              </td>
              <td class="cbs-cell text-secondary" style="text-align: right;"><small>Суточные:</small></td>
              <td class="cbs-cell" style="text-align: right;">{{ formatNumber(rec.perdiem_price.value) }}</td>
              <td class="cbs-cell" style="text-align: right;">{{ formatNumber(rec.perdiem_amount.value) }}</td>
              <td rowspan="3" class="cbs-cell" style="text-align: right;">{{ formatNumber(rec.amount.value) }}</td>
              <td rowspan="3" class="cbs-cell">{{ rec.authorsid.title }}</td>
              <td rowspan="3" class="cbs-cell">{{ rec.created.value }}</td>
            </tr>
            <tr class="cbs-row">
              <td class="cbs-cell text-secondary" style="text-align: right;"><small>Страна:</small></td>
              <td class="cbs-cell">
                <!--{{ rec.country.title }}-->
                <cbs-reference-picker3 :record="rec"
                                       :field="{ key: 'country' }"
                                       :prop-ref="ref(qryCountry)"
                                       @updateCell="onUpdateCell(rec, 'country', $event)"
                />
              </td>
              <td class="cbs-cell" style="text-align: center; min-width: 6rem;">
                <cbs-reference-picker3 :record="rec"
                                       :field="{ key: 'currency_visa' }"
                                       :prop-ref="ref(qryCurrency)"
                                       @updateCell="onUpdateCell(rec, 'currency_visa', $event)"
                />
              </td>
              <td class="cbs-cell text-secondary" style="text-align: right;"><small>Проезда:</small></td>
              <td class="cbs-cell">
                <cbs-budreq-cell :record="rec" key-value="travel_days" value-type="number" @edit="onEdit(rec, 'travel_days', $event)" />
              </td>
              <td class="cbs-cell text-secondary" style="text-align: right;"><small>Проживание:</small></td>
              <td class="cbs-cell" style="text-align: right;">{{ formatNumber(rec.accom_price.value) }}</td>
              <td class="cbs-cell" style="text-align: right;">{{ formatNumber(rec.accom_amount.value) }}</td>
            </tr>
            <tr class="cbs-row">
              <td class="cbs-cell">{{ rec.position_category.title }}</td>
              <td class="cbs-cell text-secondary" style="text-align: right;"><small>Регион:</small></td>
              <td class="cbs-cell">
                <!--{{ rec.region.title }}-->
                <cbs-reference-picker3 :record="rec"
                                       :field="{ key: 'region' }"
                                       :prop-ref="refRegionByCountry(rec)"
                                       @updateCell="onUpdateCell(rec, 'region', $event)"
                />
              </td>
              <td class="cbs-cell" style="text-align: center;">
                {{ formatNumber(rec.amount_visa.value) }}
              </td>
              <td class="cbs-cell text-secondary" style="text-align: right;"><small>Всего:</small></td>
              <td class="cbs-cell" style="text-align: center;">{{ rec.trip_days.value + rec.travel_days.value }}</td>
              <td class="cbs-cell text-secondary" style="text-align: right;"><small>Проезд:</small></td>
              <td class="cbs-cell" style="text-align: right;">{{ formatNumber(rec.travel_price.value) }}</td>
              <td class="cbs-cell" style="text-align: right;">{{ formatNumber(rec.travel_amount.value) }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <cbs-pagination v-if="dataReady" :page-size="pageSize" :record-count="qryTable.recordcount" :current-page="currentPage"
                      @page-change="onChangePageNumber"
      />
    </div>
    <b-modal :ref="'modal_del_' + uuid"
             cancel-variant="outline-warning"
             ok-title="Подтверждаю"
             cancel-title="Отмена"
             centered
             title="Подтверждение"
             @ok="deleteRecords"
             @cancel="doNothing"
             @close="doNothing"
    >
      Вы подтверждаете удаление записей?
    </b-modal>
    <b-modal :ref="'modal_recalcall_' + uuid"
             cancel-variant="outline-warning"
             ok-title="Подтверждаю"
             cancel-title="Отмена"
             centered
             title="Подтверждение"
             @ok="recalcAllRecords"
             @cancel="doNothing"
             @close="doNothing"
    >
      Вы подтверждаете пересчет всех командировок?
    </b-modal>
    <b-modal :ref="'modal_recalcall_done_' + uuid"
             ok-title="OK"
             cancel-variant="outline-secondary"
             cancel-title="Отмена"
             centered
             title="Готово"
             @ok="doNothing"
    >
      Пересчет командировок завершен.
    </b-modal>
    <cbs-debug v-if="isRoot" :context="this" />
  </div>
</template>

<script>
import CbsDebug from '@/cubus/components/debug/CbsDebug.vue'
import useCubus from '@/cubus/services/useCubus'
import {
  BButton, BButtonGroup, BButtonToolbar,
} from 'bootstrap-vue'
import CbsBudreqCell from '@/cubus/components/cbs-form/custom-forms/budget-request/CbsBudreqCell.vue'
import CbsReferencePicker3 from '@/cubus/components/reference/CbsReferencePicker3.vue'
import CbsPagination from '@/cubus/components/pagination/CbsPagination.vue'
import CbsBudreqFilter from '@/cubus/components/cbs-form/custom-forms/budget-request/CbsBudreqFilter.vue'

export default {
  name: 'CbsBudgetTripTable',
  components: {
    CbsBudreqFilter,
    CbsPagination,
    CbsReferencePicker3,
    CbsBudreqCell,
    BButton,
    BButtonToolbar,
    BButtonGroup,
    CbsDebug,
  },
  props: {
    scenario: {
      type: Object,
      default: null,
    },
  },
  setup() {
    return {
      session: useCubus.getSession(),
      isRoot: useCubus.isRoot(),
      isAdmin: useCubus.isAdmin(),
      uuid: useCubus.guid(),
      cfg: {
        // objSid_table: 'view_cube_budget_trip_current_user',
        objSid_table: 'cube_budget_trip',
        filter_notDel: useCubus.filter_notDel(),
        cond_notDel: useCubus.cond_notDel(),
        orderby_code: useCubus.orderby_code(),
        orderby_dateDesc: useCubus.orderby_dateDesc(),
      },
    }
  },
  data() {
    return {
      dataReady: false,
      qryTable: null,
      qryUnit: null,
      qryPosition: null,
      qryCategory: null,
      qryCity: null,
      qryCountry: null,
      qryRegion: null,
      qryTripItem: null,
      qryBudgetParam: null,
      qryIndicator: null,
      qryNorm: null,
      qryCurrency: null,
      qryAccessType: null,
      qryAccess: null,
      pageSize: 10,
      currentPage: 1,
      qryTotal: null,
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      this.load()
    },
    async load() {
      this.qryUnit = await useCubus.loadQuery(this, {
        keymode: 'sid', objectsid: 'business_unit', filter: this.cfg.filter_notDel, orderby: this.cfg.orderby_code, pageSize: 100000,
      })
      this.qryPosition = await useCubus.loadQuery(this, {
        keymode: 'sid', objectsid: 'position', filter: this.cfg.filter_notDel, orderby: this.cfg.orderby_code, pageSize: 100000,
      })
      this.qryCategory = await useCubus.loadQuery(this, {
        keymode: 'sid', objectsid: 'position_category', filter: this.cfg.filter_notDel, orderby: this.cfg.orderby_code, pageSize: 100000,
      })
      this.qryCity = await useCubus.loadQuery(this, {
        keymode: 'sid', objectsid: 'city', filter: this.cfg.filter_notDel, orderby: this.cfg.orderby_code, pageSize: 100000,
      })
      this.qryCountry = await useCubus.loadQuery(this, {
        keymode: 'sid', objectsid: 'country', filter: this.cfg.filter_notDel, orderby: this.cfg.orderby_code, pageSize: 100000,
      })
      this.qryRegion = await useCubus.loadQuery(this, {
        keymode: 'sid', objectsid: 'region', filter: this.cfg.filter_notDel, orderby: this.cfg.orderby_code, pageSize: 100000,
      })
      this.qryTripItem = await useCubus.loadQuery(this, {
        keymode: 'sid', objectsid: 'trip_item', filter: this.cfg.filter_notDel, pageSize: 100000,
      })
      this.qryIndicator = await useCubus.loadQuery(this, {
        keymode: 'sid', objectsid: 'budgetindicator', filter: this.cfg.filter_notDel, pageSize: 100000,
      })
      this.qryBudgetParam = await useCubus.loadQuery(this, {
        objectsid: 'cube_indicator_product_value',
        filter: this.filter_cube(),
        orderby: this.cfg.orderby_dateDesc,
        pageSize: 100000,
        keymode: 'sid',
      })
      this.qryBudgetParam.recordset.records = this.qryBudgetParam.recordset.records.filter(r => this.getIndicatorSid(r.budget_indicator.value).startsWith('rate_'))
      this.qryCurrency = await useCubus.loadQuery(this, {
        keymode: 'sid', objectsid: 'currency', filter: this.cfg.filter_notDel, pageSize: 100000,
      })
      this.qryNorm = await useCubus.loadQuery(this, {
        keymode: 'sid', objectsid: 'cube_trip_norm', filter: this.filter_cube(), pageSize: 100000,
      })
      this.qryAccessType = await useCubus.loadQuery(this, {
        keymode: 'sid', objectsid: 'accesstype', filter: this.cfg.filter_notDel, pageSize: 100000,
      })
      this.qryAccess = await useCubus.loadQuery(this, {
        objectsid: 'budget_request_access',
        filter: this.cfg.filter_notDel,
        pageSize: 100000,
        keymode: 'sid',
      })
      this.loadQryTable()
    },
    async loadQryTable() {
      this.qryTable = await useCubus.loadQuery(this, {
        objectsid: this.cfg.objSid_table,
        filter: this.filter(),
        pageSize: this.pageSize,
        offset: this.pageSize * (this.currentPage - 1),
        keymode: 'sid',
      })
      this.qryTable.recordset.records.forEach(r => this.$set(r, 'cbs_sys_access', this.getAccessSid(r)))
      this.loadTotal()
      this.dataReady = true
      this.$nextTick(() => {
        this.setStick()
      })
    },
    async loadTotal() {
      this.qryTotal = await useCubus.loadQuery(this, {
        keymode: 'sid',
        objectsid: this.cfg.objSid_table,
        filter: this.filter(),
        groupby: {
          isActive: true,
          groups: [
            { isActive: true, oper: { sid: 'sum' }, entity: { sid: 'amount' } },
            { isActive: true, oper: { sid: 'sum' }, entity: { sid: 'accom_amount' } },
            { isActive: true, oper: { sid: 'sum' }, entity: { sid: 'perdiem_amount' } },
            { isActive: true, oper: { sid: 'sum' }, entity: { sid: 'travel_amount' } },
            { isActive: true, oper: { sid: 'sum' }, entity: { sid: 'amount_visa' } },
          ],
        },
      })
    },
    getAccessSid(rec) {
      const unitid = rec.business_unit.value
      const rwValue = this.qryAccessType.recordset.records.find(r => r.sid.value === 'rw').id.value
      const accessRecord = this.qryAccess.recordset.records.some(acc => acc.business_unit.value === unitid
            && acc.indicatoraccess.value === rwValue
            && this.session.user.roles.some(role => role.id === acc.role.value))
      if (accessRecord || this.isAdmin) return 'rw'
      return 'ro'
    },
    setStick() {
      const corner = document.getElementById(`corner_${this.uuid}`)
      if (corner) {
        const offsetW = document.getElementById(`corner_${this.uuid}`).offsetWidth
        const offsetH = document.getElementById(`corner_${this.uuid}`).offsetHeight
        document.querySelectorAll('.cbs-stick-left').forEach(cell => {
          const c = cell; c.style.left = `${offsetW}px`
        })
        document.querySelectorAll('.cbs-stick-top').forEach(cell => {
          const c = cell; c.style.top = `${offsetH}px`
        })
      }
    },
    hasEmptyRecord() {
      if (!this.qryTable) return true
      return this.qryTable.recordset.records.some(r => r.id.value === null)
    },
    createRecord() {
      const rec = this.getNewRecord()
      this.qryTable.recordset.records.unshift(rec)
    },
    getNewRecord() {
      const n = {}
      this.qryTable.columns.forEach(c => {
        if (c.default) {
          n[c.entitysid] = {}
          n[c.entitysid].value = c.default.value
          n[c.entitysid].title = c.default.title
        }
      })
      n.scenario.value = this.scenario.scenario.value
      n.scenario.title = this.scenario.scenario.title
      n.authorsid.value = this.session.user.sid
      n.authorsid.title = this.session.user.name
      n.created.value = useCubus.dateToString(new Date())
      n.cbs_sys_access = 'rw'
      return JSON.parse(JSON.stringify(n))
    },
    selected() {
      if (!this.qryTable) return []
      return this.qryTable.recordset.records.filter(r => r.cbs_sys_selected)
    },
    askDelete() {
      this.$refs[`modal_del_${this.uuid}`].show()
    },
    askRecalcAll() {
      this.$refs[`modal_recalcall_${this.uuid}`].show()
    },
    deleteRecords() {
      this.qryTable.recordset.records.filter(r => r.cbs_sys_selected).forEach(r => {
        if (r.sid.value) {
          this.deleteRecord(r, this.qryTable.recordset.records)
        } else {
          this.qryTable.recordset.records.splice(this.qryTable.recordset.records.indexOf(r), 1)
        }
      })
    },
    async deleteRecord(record, records) {
      try {
        await useCubus.delRecord(this, { objectsid: this.cfg.objSid_table, record })
        records.splice(this.qryTable.recordset.records.indexOf(record), 1)
      } catch (e) { console.error(e) }
    },
    selectRec(rec) {
      if (rec.cbs_sys_access === 'rw') {
        if (!rec.cbs_sys_selected) this.$set(rec, 'cbs_sys_selected', true)
        else {
          const r = rec
          r.cbs_sys_selected = !rec.cbs_sys_selected
        }
      }
    },
    onEdit(rec, key, value) {
      const r = rec
      r[key].value = value
      r[key].status = 'changed'
      // this.setNorm(rec)
      this.recalcRecord(rec)
      this.saveRecord(rec)
    },
    onUpdateCell(rec, key, val) {
      const r = rec
      r[key].value = val.value
      r[key].title = val.title
      r[key].status = 'changed'
      if (key === 'position') this.setCategory(rec)
      if (key === 'country') this.setRegion(rec)
      if (key === 'city') this.setCountry(rec)
      // this.setNorm(rec)
      this.recalcRecord(rec)
      this.saveRecord(rec)
    },
    setCategory(rec) {
      const el = this.qryPosition.recordset.records.find(r => r.id.value === rec.position.value)
      const r = rec
      if (el) {
        r.position_category.value = el.position_category.value
        r.position_category.title = el.position_category.title
      } else {
        r.position_category.value = null
        r.position_category.title = null
      }
      r.position_category.status = 'changed'
      // this.setNorm(rec)
      this.recalcRecord(rec)
    },
    recalcRecord(rec) {
      this.setNorm(rec)
    },
    setNorm(rec) {
      this.setNormTravel(rec)
      this.setNormAccom(rec)
      this.setNormPerdiem(rec)
      this.setVisa(rec)
      this.setTotalAmount(rec)
      this.saveRecord(rec)
    },
    setVisa(rec) {
      const visaRate = this.getCurRate(rec.currency_visa.value, rec.date.value)
      const r = rec
      r.amount_visa.value = rec.amount_cur_visa.value * visaRate
      r.amount_visa.status = 'changed'
      // this.setTotalAmount(rec)
    },
    setNormTravel(rec) {
      const el = this.getNorm(rec.position_category.value, rec.region.value, 'travel')
      const r = rec
      if (el) {
        r.travel_cur.value = el.currency.value
        r.travel_cur.title = el.currency.title
        r.travel_price_cur.value = el.curamount.value
        const curRate = this.getCurRate(el.currency.value, rec.date.value)
        r.travel_price.value = el.curamount.value * curRate
        r.travel_amount_cur.value = rec.travel_price_cur.value * rec.person_qty.value
        r.travel_amount.value = rec.travel_amount_cur.value * curRate
      } else {
        r.travel_cur.value = null
        r.travel_cur.title = null
        r.travel_price_cur.value = null
        r.travel_price.value = null
        r.travel_amount_cur.value = null
        r.travel_amount.value = null
      }
      r.travel_cur.status = 'changed'
      r.travel_price_cur.status = 'changed'
      r.travel_price.status = 'changed'
      r.travel_amount_cur.status = 'changed'
      r.travel_amount.status = 'changed'
      // this.setTotalAmount(rec)
    },
    setNormAccom(rec) {
      const el = this.getNorm(rec.position_category.value, rec.region.value, 'accom')
      const r = rec
      if (el) {
        r.accom_cur.value = el.currency.value
        r.accom_cur.title = el.currency.title
        r.accom_price_cur.value = el.curamount.value
        const curRate = this.getCurRate(el.currency.value, rec.date.value)
        r.accom_price.value = el.curamount.value * curRate
        r.accom_amount_cur.value = rec.accom_price_cur.value * rec.person_qty.value * rec.trip_days.value
        r.accom_amount.value = rec.accom_amount_cur.value * curRate
      } else {
        r.accom_cur.value = null
        r.accom_cur.title = null
        r.accom_price_cur.value = null
        r.accom_price.value = null
        r.accom_amount_cur.value = null
        r.accom_amount.value = null
      }
      r.accom_cur.status = 'changed'
      r.accom_price_cur.status = 'changed'
      r.accom_price.status = 'changed'
      r.accom_amount_cur.status = 'changed'
      r.accom_amount.status = 'changed'
      // this.setTotalAmount(rec)
    },
    setNormPerdiem(rec) {
      const el = this.getNorm(rec.position_category.value, rec.region.value, 'perdiem')
      const r = rec
      if (el) {
        r.perdiem_cur.value = el.currency.value
        r.perdiem_cur.title = el.currency.title
        r.perdiem_price_cur.value = el.curamount.value
        const curRate = this.getCurRate(el.currency.value, rec.date.value)
        r.perdiem_price.value = el.curamount.value * curRate
        r.perdiem_amount_cur.value = rec.perdiem_price_cur.value * rec.person_qty.value * (rec.trip_days.value + rec.travel_days.value)
        r.perdiem_amount.value = rec.perdiem_amount_cur.value * curRate
      } else {
        r.perdiem_cur.value = null
        r.perdiem_cur.title = null
        r.perdiem_price_cur.value = null
        r.perdiem_price.value = null
        r.perdiem_amount_cur.value = null
        r.perdiem_amount.value = null
      }
      r.perdiem_cur.status = 'changed'
      r.perdiem_price_cur.status = 'changed'
      r.perdiem_price.status = 'changed'
      r.perdiem_amount_cur.status = 'changed'
      r.perdiem_amount.status = 'changed'
      // this.setTotalAmount(rec)
    },
    setTotalAmount(rec) {
      const r = rec
      r.amount.value = rec.perdiem_amount.value + rec.accom_amount.value + rec.travel_amount.value + rec.amount_visa.value
      r.amount.status = 'changed'
    },
    getNorm(categoryId, regionId, tripItem) {
      return this.qryNorm.recordset.records.find(r => r.position_category.value === categoryId
          && r.region.value === regionId
          && this.tripItemSid(r.trip_item.value) === tripItem)
    },
    tripItemSid(id) {
      return this.qryTripItem.recordset.records.find(r => r.id.value === id).sid.value
    },
    setCountry(rec) {
      const el = this.country(rec.city.value)
      const r = rec
      if (el) {
        r.country.value = el.value
        r.country.title = el.title
      } else {
        r.country.value = null
        r.country.title = null
      }
      r.country.status = 'changed'
      this.setRegion(rec)
    },
    setRegion(rec) {
      const regions = this.qryRegion.recordset.records.filter(r => r.country.value === rec.country.value)
      const r = rec
      if (regions.length === 1) {
        r.region.value = regions[0].id.value
        r.region.title = regions[0].name.value
      } else {
        const region = regions.find(rg => rg.name.value.includes(rec.city_name.value))
        if (region) {
          r.region.value = region.id.value
          r.region.title = region.name.value
        } else {
          r.region.value = null
          r.region.title = null
        }
      }
      r.region.status = 'changed'
      // this.setNorm(rec)
      this.recalcRecord(rec)
    },
    async saveRecord(record) {
      const saved = await useCubus.saveRecord(this, { keymode: 'sid', objectsid: this.cfg.objSid_table, record })
      this.afterSave(record, saved)
    },
    afterSave(initRec, savedRec) {
      const s = savedRec
      const i = initRec
      s.status = 'saved'
      this.qryTable.columns.forEach(fld => {
        if (fld.key !== 'actions') {
          i[fld.entitysid].value = savedRec[fld.entitysid].value
          i[fld.entitysid].title = savedRec[fld.entitysid].title
        }
      })
      i.status = 'saved'
      this.loadTotal()
    },
    ref(qry) {
      if (!qry) return []
      return qry.recordset.records.map(r => ({ value: r.id.value, title: r.name.value }))
    },
    styleStickLeft() {
      const corner = document.getElementById(`corner_${this.uuid}`)
      if (!corner) {
        return {
          position: 'sticky',
          left: 0,
          'z-index': 1,
        }
      }
      return {
        position: 'sticky',
        left: `${corner.offsetWidth}px`,
        'z-index': 1,
      }
    },
    country(cityId) {
      if (!cityId) return null
      if (!this.qryCity) return null
      const el = this.qryCity.recordset.records.find(r => r.id.value === cityId)
      if (el) return el.country
      return null
    },
    region(coutryId) {
      if (!coutryId) return null
      if (!this.qryCountry) return null
      const el = this.qryCountry.recordset.records.find(r => r.id.value === coutryId)
      if (el) return el.region
      return null
    },
    getIndicatorSid(id) {
      const ind = this.qryIndicator.recordset.records.find(r => r.id.value === id)
      if (!ind) return null
      return ind.sid.value
    },
    getCurRate(curId, date) {
      if (!curId) return 1
      const curSid = this.qryCurrency.recordset.records.find(r => r.id.value === curId).sid.value.toLowerCase()
      const ind = this.qryBudgetParam.recordset.records.find(r => this.getIndicatorSid(r.budget_indicator.value) === `rate_${curSid}`
          && (new Date(date) - new Date(r.date.value)) >= 0)
      if (ind) return ind.indicator_value.value
      return 1
    },
    onChangePageNumber(page) {
      this.currentPage = page
      this.loadQryTable()
    },
    getColumn(key) {
      return this.qryTable.columns.find(c => c.entitysid === key)
    },
    getCondition(key) {
      const col = this.qryTable.columns.find(c => c.entitysid === key)
      if (!col) return null
      const cond = this.filter().node.nodes[0].conds.find(c => c.args[0].value === col.entityid)
      if (cond) return cond
      const newCond = this.getNewCondition(col)
      this.filter().node.nodes[0].conds.push(newCond)
      return newCond
    },
    getNewCondition(col) {
      const operSid = col.datatype === 'string' ? 'contain' : 'equal'
      return {
        isactive: false,
        oper: { sid: operSid },
        args: [
          { type: { sid: 'entity' }, value: col.entityid },
          { type: { sid: 'value' }, value: null },
        ],
      }
    },
    applyFilter() {
      this.loadQryTable('filter')
    },
    toggleFilter(key) {
      if (key === 'city') {
        this.$refs[`flt_country_${this.uuid}`].collapse()
        this.$refs[`flt_region_${this.uuid}`].collapse()
      }
      if (key === 'country') {
        this.$refs[`flt_city_${this.uuid}`].collapse()
        this.$refs[`flt_region_${this.uuid}`].collapse()
      }
      if (key === 'region') {
        this.$refs[`flt_city_${this.uuid}`].collapse()
        this.$refs[`flt_country_${this.uuid}`].collapse()
      }
      if (key === 'position') this.$refs[`flt_category_${this.uuid}`].collapse()
      if (key === 'category') this.$refs[`flt_position_${this.uuid}`].collapse()
      this.$refs[`flt_${key}_${this.uuid}`].expand()
    },
    refRegionByCountry(rec) {
      return this.qryRegion.recordset.records
        .filter(r => r.country.value === rec.country.value)
        .map(r => ({ value: r.id.value, title: r.name.value }))
    },
    formatDate(value) {
      return this.toDate(value)
    },
    formatNumber(value) {
      return Number(value).toLocaleString()
    },
    doNothing() {
      return null
    },
    recalcRecords() {
      this.qryTable.recordset.records.filter(r => r.cbs_sys_selected).forEach(r => {
        this.recalcRecord(r)
      })
      this.clearSelected()
    },
    async recalcAllRecords() {
      this.pageSize = 100000
      await this.loadQryTable()
      this.qryTable.recordset.records.forEach(r => {
        this.recalcRecord(r)
      })
      this.pageSize = 10
      await this.loadQryTable()
      this.$refs[`modal_recalcall_done_${this.uuid}`].show()
    },
    clearSelected() {
      this.qryTable.recordset.records.filter(r => r.cbs_sys_selected).forEach(rec => {
        const r = rec; r.cbs_sys_selected = false
      })
    },

    cond_scn() {
      return {
        isactive: true,
        oper: { sid: 'equal' },
        args: [
          { type: { sid: 'entitysid' }, value: 'scenario' },
          { type: { sid: 'value' }, value: this.scenario.scenario.value },
        ],
      }
    },
    filter_cube() {
      return {
        isactive: true,
        node: {
          isactive: true,
          oper: { sid: 'and' },
          nodes: [],
          conds: [
            this.cfg.cond_notDel,
            this.cond_scn(),
          ],
        },
      }
    },
    filter() {
      return {
        isactive: true,
        node: {
          isactive: true,
          oper: { sid: 'and' },
          nodes: [
            {
              isactive: true,
              oper: { sid: 'and', name: 'AND' },
              nodes: [],
              conds: [],
            },
          ],
          conds: [
            {
              isactive: true,
              oper: { sid: 'equal' },
              args: [
                { type: { sid: 'entitysid' }, value: 'del' },
                { type: { sid: 'value' }, value: false },
              ],
            },
            {
              isactive: true,
              oper: { sid: 'equal' },
              args: [
                { type: { sid: 'entitysid' }, value: 'scenario' },
                { type: { sid: 'value' }, value: this.scenario.scenario.value },
              ],
            },
          ],
        },
      }
    },
  },
}
</script>

<style scoped>
.cbs-th-corner {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 3;
}
.cbs-th {
  position: sticky;
  top: 0;
  z-index: 2;
  padding: 0.5rem 1rem 0.5rem 1rem;
}
.cbs-th1 {
  position: sticky;
  top: 0;
  z-index: 3;
  padding: 0.5rem 1rem 0.5rem 1rem;
}
.cbs-table {
  overflow: scroll;
  max-height: 70vh;
}
.cbs-cell {
  padding: 0.25rem 0.5rem 0.25rem 0.5rem;
}
.cbs-row {
  padding: 0;
}
.cbs-flt {
  position: sticky;
  z-index: 2;
}
.cbs-flt1 {
  position: sticky;
  z-index: 3;
}
.cbs-flt-corner {
  position: sticky;
  left: 0;
  z-index: 3;
}
.cbs-col0 {
  position: sticky;
  left: 0;
  z-index: 1;
}
.flt0 {
  text-transform: none;
  font-weight: normal;
}
</style>
