<template>
<div>
  <div v-if="qryPeriods">
    <cbs-cash-request-period v-for="(rec, idx) in qryPeriods.recordset.records" :key="idx" style="margin-bottom: 1rem;" :period="rec.date.value.slice(0, 10)" />
  </div>
  <cbs-cash-request-period v-if="!isCurrentPeriodExists()" :period="currentPeriod" />
  <cbs-debug v-if="isRoot" :context="this" />
</div>
</template>

<script>
import CbsDebug from '@/cubus/components/debug/CbsDebug.vue'
import useCubus from '@/cubus/services/useCubus'
import CbsCashRequestPeriod from '@/cubus/components/cbs-form/custom-forms/cash-request/CbsCashRequestPeriod.vue'

export default {
  name: 'CbsCashRequest',
  components: {
    CbsCashRequestPeriod,
    CbsDebug,
  },
  props: {
    objectFull: {
      type: Object,
      default: null,
    },
  },
  setup() {
    return {
      isRoot: useCubus.isRoot(),
    }
  },
  data() {
    return {
      currentPeriod: null,
      qryPeriods: null,
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      // this.currentPeriod = this.getConditionalFirstMonday()
      this.currentPeriod = this.getStartOfTargetMonth()
      this.loadPeriods()
    },
    async loadPeriods() {
      const params = {
        keymode: 'sid',
        objectsid: 'cash_request',
        filter: {
          isactive: true,
          node: {
            isactive: true,
            oper: { sid: 'and' },
            nodes: [],
            conds: [
              useCubus.cond_notDel(),
            ],
          },
        },
        groupby: {
          isActive: true,
          groups: [
            {
              isActive: true,
              oper: { sid: 'month' },
              entity: { sid: 'date' },
            },
          ],
        },
        orderby: useCubus.orderby_date(),
        pageSize: 100000,
      }
      this.qryPeriods = await useCubus.loadQuery(this, params)
    },
    getConditionalFirstMonday() {
      const today = new Date() // Сегодняшняя дата
      const year = today.getFullYear() // Текущий год
      const currentMonth = today.getMonth() // Текущий месяц (0 - январь)
      const dayOfMonth = today.getDate() // Сегодняшний день месяца

      // Определяем месяц, для которого нужно найти первый понедельник
      const targetMonth = dayOfMonth < 25 ? currentMonth : currentMonth + 1

      // Вычисляем первый понедельник указанного месяца
      const res = this.getFirstMonday(year, targetMonth)

      const yr = res.getFullYear()
      const mn = String(res.getMonth() + 1).padStart(2, '0') // Месяцы начинаются с 0
      const dy = String(res.getDate()).padStart(2, '0') // Добавляем ведущий ноль

      // Форматируем строку в формате DD.MM.YYYY
      // return `${dy}.${mn}.${yr}`
      return `${yr}-${mn}-${dy}`
    },
    getFirstMonday(year, month) {
      let yr = year
      let mn = month
      // Если month больше 11, переходим на следующий год
      if (month > 11) {
        yr += 1
        mn = 0 // Январь следующего года
      }

      // Создаем дату первого дня месяца
      const date = new Date(yr, mn, 1)

      // Проверяем, является ли первый день месяца понедельником
      while (date.getDay() !== 1) {
        // Если нет, добавляем один день
        date.setDate(date.getDate() + 1)
      }

      // Возвращаем первый понедельник
      return date
    },
    isCurrentPeriodExists() {
      if (!this.qryPeriods) return false
      return this.qryPeriods.recordset.records.some(rec => rec.date.value.startsWith(this.currentPeriod))
    },

    getStartOfTargetMonth() {
      const today = new Date() // Сегодняшняя дата
      const currentMonth = today.getMonth() // Текущий месяц (0 - январь)
      const dayOfMonth = today.getDate() // Сегодняшний день месяца

      // Определяем месяц, для которого нужно найти первый понедельник
      const targetMonth = dayOfMonth < 25 ? currentMonth : currentMonth + 1
      const year = today.getFullYear() + (targetMonth > 11 ? 1 : 0)
      const month = targetMonth > 11 ? 0 : targetMonth
      const day = 1

      return `${year}-${String(month + 1).padStart(2, '0')}-${String(day).padStart(2, '0')}`
    },
  },
}
</script>

<style scoped>

</style>
