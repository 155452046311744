<template>
<div>
  <div style="margin-bottom: 1rem;">
    <div style="margin-bottom: 0.5rem;">Сценарий:</div>
    <cbs-reference-picker :record="recScenario" :field="fldScenario" :prop-ref="refScenario()" variant="primary" @updateCell="selectScenario()" />
  </div>
  <div v-if="recScenario.scenario.value && qryPeriods">
    <cbs-paycal-period v-for="(rec, idx) in qryPeriods.recordset.records" :key="idx"
                       :period="rec.date.value.slice(0,10)"
                       :scenario="scenario()"
                       :ref-scenario="refScenario()"
    />
  </div>
  <cbs-paycal-period v-if="!isCurrentPeriodExists()"
                     :period="currentPeriod"
                     :scenario="scenario()"
                     :ref-scenario="refScenario()"
  />
  <cbs-debug v-if="isRoot" :context="this" />
</div>
</template>

<script>
import CbsDebug from '@/cubus/components/debug/CbsDebug.vue'
import useCubus from '@/cubus/services/useCubus'
import CbsPaycalPeriod from '@/cubus/components/cbs-form/custom-forms/payment-calendar/CbsPaycalPeriod.vue'
import CbsReferencePicker from '@/cubus/components/reference/CbsReferencePicker.vue'

export default {
  name: 'CbsPaycal',
  components: {
    CbsReferencePicker,
    CbsPaycalPeriod,
    CbsDebug,
  },
  props: {
    objectFull: {
      type: Object,
      default: null,
    },
  },
  setup() {
    return {
      isRoot: useCubus.isRoot(),
    }
  },
  data() {
    return {
      currentPeriod: null,
      qryPeriods: null,
      qryScenario: null,
      recScenario: {
        scenario: {
          value: null,
          title: null,
        },
      },
      fldScenario: {
        entitysid: 'scenario',
        datatypesid: 'ref',
        key: 'scenario',
      },
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      // this.currentPeriod = this.getConditionalFirstMonday()
      this.currentPeriod = this.getStartOfTargetMonth()
      this.loadScenarios()
    },
    async loadScenarios() {
      this.qryScenario = await useCubus.loadQuery(this, {
        keymode: 'sid',
        objectsid: 'scenario',
        filter: {
          isactive: true,
          node: {
            isactive: true,
            oper: { sid: 'and' },
            nodes: [],
            conds: [
              useCubus.cond_notDel(),
              {
                isactive: true,
                oper: { sid: 'equal' },
                args: [
                  { type: { sid: 'entitysid' }, value: 'istreasury' },
                  { type: { sid: 'value' }, value: true },
                ],
              },
            ],
          },
        },
        orderby: {
          isActive: true,
          sorts: [
            {
              isActive: true,
              oper: { sid: 'asc' },
              entity: { sid: 'name' },
            },
          ],
        },
        pageSize: 100000,
      })
      await this.initScenario()
    },
    async initScenario() {
      const scnDefault = this.qryScenario.recordset.records.find(rec => rec.sid.value === 'paycal')
      if (scnDefault) {
        this.recScenario.scenario.value = scnDefault.id.value
        this.recScenario.scenario.title = scnDefault.name.value
      } else {
        const firstScenario = this.qryScenario.recordset.records.find(rec => rec.sid.value === 'paycal')
        if (firstScenario) {
          this.recScenario.scenario.value = firstScenario.id.value
          this.recScenario.scenario.title = firstScenario.name.value
        }
      }
      // todo: create default scenario
      await this.loadPeriods()
    },
    async loadPeriods() {
      this.qryPeriods = await useCubus.loadQuery(this, {
        keymode: 'sid',
        objectsid: 'cube_paycal',
        filter: {
          isactive: true,
          node: {
            isactive: true,
            oper: { sid: 'and' },
            nodes: [],
            conds: [
              useCubus.cond_notDel(),
              {
                isactive: true,
                oper: { sid: 'equal' },
                args: [
                  { type: { sid: 'entitysid' }, value: 'scenario' },
                  { type: { sid: 'value' }, value: this.recScenario.scenario.value },
                ],
              },
            ],
          },
        },
        groupby: {
          isActive: true,
          groups: [
            {
              isActive: true,
              oper: { sid: 'month' },
              entity: { sid: 'date' },
            },
          ],
        },
        orderby: useCubus.orderby_date(),
        pageSize: 100000,
      })
      // this.initPeriods()
    },
    initPeriods() {
      this.qryPeriods.recordset.records.forEach(rec => {
        const r = rec
        const [year, month, day] = rec.date.value.slice(0, 10).split('-').map(Number)
        const date = new Date(year, month - 1, day)
        const dayOfWeek = date.getDay()
        const daysToAdd = (8 - dayOfWeek) % 7
        date.setDate(date.getDate() + daysToAdd)
        // const formattedDate = date.toLocaleDateString('ru-RU', { day: '2-digit', month: '2-digit', year: 'numeric' })
        const y = date.getFullYear()
        const m = String(date.getMonth() + 1).padStart(2, '0') // Месяц: 0-11, поэтому +1
        const d = String(date.getDate()).padStart(2, '0')
        // const h = String(date.getUTCHours()).padStart(2, '0')
        // const mm = String(date.getUTCMinutes()).padStart(2, '0')
        // const s = String(date.getUTCSeconds()).padStart(2, '0')
        // r.date.value = formattedDate
        r.date.value = `${y}-${m}-${d}`
      })
    },
    getConditionalFirstMonday() {
      const today = new Date() // Сегодняшняя дата
      const year = today.getFullYear() // Текущий год
      const currentMonth = today.getMonth() // Текущий месяц (0 - январь)
      // const dayOfMonth = today.getDate() // Сегодняшний день месяца

      // Определяем месяц, для которого нужно найти первый понедельник
      // const targetMonth = dayOfMonth < 25 ? currentMonth : currentMonth + 1
      const targetMonth = currentMonth

      // Вычисляем первый понедельник указанного месяца
      const res = this.getFirstMonday(year, targetMonth)

      const yr = res.getFullYear()
      const mn = String(res.getMonth() + 1).padStart(2, '0') // Месяцы начинаются с 0
      const dy = String(res.getDate()).padStart(2, '0') // Добавляем ведущий ноль

      // Форматируем строку в формате DD.MM.YYYY
      return `${yr}-${mn}-${dy}`
    },
    getFirstMonday(year, month) {
      let y = year
      let m = month
      // Если month больше 11, переходим на следующий год
      if (month > 11) {
        y += 1
        m = 0 // Январь следующего года
      }

      // Создаем дату первого дня месяца
      const date = new Date(y, m, 1)

      // Проверяем, является ли первый день месяца понедельником
      while (date.getDay() !== 1) {
        // Если нет, добавляем один день
        date.setDate(date.getDate() + 1)
      }

      // Возвращаем первый понедельник
      return date
    },
    isCurrentPeriodExists() {
      if (!this.qryPeriods) return false
      return this.qryPeriods.recordset.records.some(rec => rec.date.value.startsWith(this.currentPeriod))
    },
    refScenario() {
      if (!this.qryScenario) return []
      return this.qryScenario.recordset.records.map(r => ({ value: r.id.value, title: r.name.value }))
    },
    scenario() {
      if (!this.qryScenario || !this.recScenario.scenario.value) return null
      return this.qryScenario.recordset.records.find(r => r.id.value === this.recScenario.scenario.value)
    },
    selectScenario() {
      this.qryPeriods = null
      this.loadPeriods()
    },

    getStartOfTargetMonth() {
      const today = new Date() // Сегодняшняя дата
      const currentMonth = today.getMonth() // Текущий месяц (0 - январь)
      const dayOfMonth = today.getDate() // Сегодняшний день месяца

      // Определяем месяц, для которого нужно найти первый понедельник
      const targetMonth = dayOfMonth < 25 ? currentMonth : currentMonth + 1
      const year = today.getFullYear() + (targetMonth > 11 ? 1 : 0)
      const month = targetMonth > 11 ? 0 : targetMonth
      const day = 1

      return `${year}-${String(month + 1).padStart(2, '0')}-${String(day).padStart(2, '0')}`
    },
  },
}
</script>

<style scoped>

</style>
